import { useEffectOnce } from "react-use";
import { openInvite } from "shared/client.telefunc";
import { usePageContext } from "src/hooks/usePageContext";
import { useQuery } from "src/hooks/useQuery";
import { withSuspense } from "src/utils/withSuspense";
import { InvalidPage } from "./invalid";
import { Welcome } from "./page";
import Cookies from "js-cookie";

export const Page = withSuspense(() => {
  const { urlParsed } = usePageContext();
  const inviteId = urlParsed.search.invite;
  const { data: invite } = useQuery(["invite", inviteId], () =>
    openInvite(inviteId)
  );

  useEffectOnce(() => {
    Cookies.set("invite-id", inviteId, { path: "/" });
    Cookies.set("lk-token", invite.token, { path: "/" });
  });

  if (!invite || !invite.valid) {
    return <InvalidPage />;
  }

  return <Welcome />;
});
